.App {
  background-color: #72361a;
  width: auto;
  margin: auto;
  font-family: 'Tajawal', sans-serif;
  
  
}

body{
  background-color: #72361a;
  height: 1em; 
}



.App-header {
  min-height: 120vh;
  font-size: calc(10px + 2vmin);
  color: white;
  width: auto;
  
}

.App-link {
  color: #61dafb;
}


#Back{
  display: block;
  margin: auto;
  width: 1000px;
  height: 1000px;
}

#im{
  display: none;
}

#jumb{
  background-color: transparent;
}


p>a{
  text-decoration: none;
  color: rgb(251, 158, 7);
}

p>a:hover {
  text-decoration: none;
}



.Dynamic{
  width: 23vh;
  height: 20%;
}

  .Note{
    
  font-size: 1.5vh;
  }
  .BNote{
   
  font-size: 2vh;
  }

  .Center{
    text-align: center;
    align-items: center;
    justify-content: center;

  }


  .Arabic{
    text-align: right;
    align-items: right;
  justify-content: right;
  }
  

  .grid-container {
    display: grid;
    grid-template-columns: auto ;
  }

  strong{
    color: salmon;
      font-size: 2vh;
  }

  .TableWidth{
    width: 100px;
  }

  .TableHeight{
    height: 100px;
    background-color: black;
  }

  table{
background-color: black;
  }

  #TImage{
    background-color: black;
    margin-left: auto;
    margin-right: auto;
  }

  img.map, map area{
    outline: none;
}



  
@media only screen and (max-width: 999px) {
  /* For mobile phones: */
  

  #Back{
  width: 100%;
  height: 100%;
  }

  

  }
